<template>
  <dialog-edit :value="value" @input="$emit('input')" maxWidth="1300px" :hasHint="false">
    <template v-slot:title>
      {{ data.name }}
      <small v-if="id"> ({{ id }})</small>
    </template>
    <div v-if="info">
      <v-card-title>Измененные инструкции</v-card-title>
      <div class="pb-4 text-left mb-2">
        <v-btn v-for="i of info" :key="i.id" @click.stop="$emit('showInfo', i.id)"> {{ i.name }}</v-btn>
      </div>
    </div>
    <a-loader v-if="loading" />
    <div v-else>
      <div style="text-align: initial" ref="contentWrapper1"></div>
    </div>
    <template v-if="!loading" v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('input')">Ознакомился</v-btn>
    </template>
    <a-view-video v-if="fullVideo" v-model="fullVideo" :src="srcVideo" />
  </dialog-edit>
</template>

<script>
import { nextTick } from "vue";

export default {
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      api1: "/mechti/change_log",
      data: {},
      loading: false,
      fullVideo: false,
      srcVideo: "",
      info: null,
    };
  },
  computed: {
    api() {
      return "/mechti/change_log";
    },
    content() {
      let res = `<style>
h1,h2,h3,h4 {  margin: 10px 0 20px 0; }
ul,ol {margin-bottom: 16px;}  
ul {   list-style-type: disc; }
ol {   list-style-type: decimal;}
</style>`;

      res += this.data.content;

      return res;
    },
  },
  created() {
    if (this.id) this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    async updateData() {
      this.loading = true;
      this.info = null;
      const r = await this.$axios.get(this.api + "/" + this.id);

      this.data = r.data.data;
      if (this.data?.data?.info && this.data?.data?.info?.[0]) {
        const res = await this.$axios.get("/mechti/information", { params: { filters: { id: this.data.data.info } } });
        this.info = res.data.data;
      }
      this.loading = false;
      this.$nextTick(() => {
        this.$refs.contentWrapper1.innerHTML = this.content;
        const videoElement = this.$refs.contentWrapper1.querySelector("video");
        //videoElement.addEventListener("click", this.handleVideoClick);
      });
    },
    handleVideoClick(event) {
      event.preventDefault;
      // Обработка клика по видео
      const videoElement = event.target;
      // Получаем значение атрибута src у элемента <video>
      const videoSource = videoElement.firstElementChild.getAttribute("src");

      console.log("Клик по видео", videoSource);
      if (videoSource) {
        this.srcVideo = videoSource;
        this.fullVideo = true;
      }
    },
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4 {
  margin: 10px 0 20px 0;
}
.video-container {
  width: 100%;
}

.video-container video {
  width: 100%;
  height: auto;
}
</style>
